export const AUTH_API_BASE_PATH = "/auth";

export const USER_API_BASE_PATH = "/users";

export const EXAM_API_BASE_PATH = "/exams";

export const CATEGORY_API_BASE_PATH = "/category";

export const TYPES_API_BASE_PATH = "/types";

export const SUBCATEGORY_API_BASE_PATH = "/subcategory";

export const QUESTION_API_BASE_PATH = "/question";

export const ANSWER_API_BASE_PATH = "/answer";

export const BLOG_API_BASE_PATH = "/blog";

export const BLOG_AUTHOR_API_BASE_PATH = "/authors";

export const BLOG_CATEGORY_API_BASE_PATH = "/categories";

export const LESSON_API_BASE_PATH = "/lesson";

export const MOCK_EXAM_API_BASE_PATH = "/mock-exam";

export const STUDENT_LESSON_HISTORY_API_BASE_PATH = "/student-lesson-history";

export const STUDENT_RESULT = "/result";

export const CMS_PAGES = "/cms-pages";

export const STUDENT_RESULT_QUESTION = "/result/question";

export const MOCK_EXAM_RESULT = "/result/mock-exam";

export const PLANS_API_BASE_PATH = "/plans";

export const STUDENT_GET_EXAM_LIST_API = "/exams/get-practice-exam-list";

export const STUDENT_GET_EXAM_CONTENT_API = "/exams/get-practice-exam-detail";
